// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

import $ from 'jquery';
global.$ = jQuery;

require('jquery-migrate')
require('jquery.easing')
require('bootstrap')
require('jquery-validation')

require('../plugins/lightbox')
require('../plugins/owl.carousel.min')
require('../plugins/waypoints.min')
require('../plugins/cssua.min')
require('../plugins/wow.min')
require('../plugins/jquery.flexslider')
require('../plugins/jquery.jelect')
require('../plugins/jquery.sliderPro.min')
require('../plugins/custom')
